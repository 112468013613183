<template>
  <div>
    <NftsChosen @emitChosenNftOption="chosenNftOption" />
    <NftForm :propsSelectionOption="midleWare" />
    <Footer />
  </div>
</template>

<script>
import NftForm from '../components/NftForm.vue'
import NftsChosen from '../components/NftsChosen'
import Footer from '../components/footer'

export default {
  name: 'IndexPage',
  data:() => ({
    midleWare: 0,
  }),
  components: {
    NftForm,
    NftsChosen,
    Footer,
  },
  methods: {
    chosenNftOption(nftOptionId) {
      this.midleWare = nftOptionId
    }
  },
  mounted(){}
}
</script>
